import { useState, useRef, useEffect } from 'react'
import { AlertDanger, AlertSuccess, AlertInfoLoading } from '../../alerts/Alerts';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadDocument, getFolderTreeForFile, getMaxFileUploads} from '../api';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../auth';
import { CurrentUploadPath } from './CurrentUploadPath';
import { FormattedMessage } from 'react-intl';
import {File} from './File'
import useDrivePicker from 'react-google-drive-picker';
import { useAppContext } from '../../../pages/AppContext/AppContext';

export const DragDropFile = (props: any) => {
  // drag state
  const { state }: any = useLocation()
  const currentCommunityId = state.currentCommunity
  const currentParent = state.currentParent
  const [folderTree, setFolderTree] = useState<any>([])
  const [dragActive, setDragActive] = useState(false);
  const [successResMessage, setSuccessResMessage] = useState<string>('')
  const [failureResMessage, setFailureResMessage] = useState<string>('')
  const [checked, setChecked] = useState<boolean>(true)
  const [uploading, setUploading] = useState<boolean | string>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [maxFileUploads,setMaxFileUploads] = useState<number>(0)

  // ref
  const inputRef = useRef<any>(null);
  const { auth, isBackFromPages, setIsBackFromPages, communityList,currentCommunity } = useAuth()
  const [files, setFiles] = useState<any[]>([]);

  // Google Drive config
  const { appData } = useAppContext()
  const [gdLoading, setgdLoading] = useState(false)

  const [openPicker, authResponse] = useDrivePicker();
  const [gdFile, setGDFile] = useState<any>([]);
  const [gdBlob, setGDBlob] = useState([]);
  const [gdauth, setGDAuth] = useState<String | undefined>('')

  let currentCommunityTitle = '';
  communityList.forEach(community=>{
    if(currentCommunity===community.id){
      currentCommunityTitle = community.community_name
    }
  })

  const handleOpenPicker = () => {
    openPicker({
      clientId: appData.gdriveClientId,
      developerKey: appData.gdriveDeveloperKey,
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ['https://www.googleapis.com/auth/drive.readonly'],
      callbackFunction: (data: any) => {
        if (data.action === 'picked') {
          const filesWithExtensions = data.docs.map((file: any) => ({
            ...file,
            name: file.name
          }));
          setgdLoading(true)
          setGDFile(filesWithExtensions);
        }
      },
    });
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (gdFile.length > 0) {
        try {
          const dfiles = await Promise.allSettled(
            gdFile.map(async (file: any) => {
              try {
                const fileData = await downloadFile(file);
                setGDBlob((prevGDBlob): any => [...prevGDBlob, fileData]);
                return fileData
              } catch (error) {
                console.error('Error downloading file:', error);
              }
            })
          );
          if(dfiles.length > 0) {
            if(maxFileUploads >= (dfiles.length)){
              let ufile: any = []
              dfiles.forEach((file: any, i)=> {
                if(isValidFileType(file.value)) {
                  file.value.name = gdFile[i].name;
                  const CustomFile = window.File;
                  const NewFile =  new CustomFile([file.value], file.value.name, {
                    type: file.value.type,
                  });
                  ufile.push(NewFile)
                }
                else {
                    setgdLoading(false)
                    setFailureResMessage("Invalid file format, only supported format are .doc, .docx, .xlsx, .pdf, .pptx, .txt, mp3, mp4, jpg, jpeg, png and mov")
                    setChecked(true)
                }
             
              })
              setFiles(ufile)
              setgdLoading(false)
            }
            else {
              setgdLoading(false)
              setFailureResMessage(
                `Cannot upload More than ${maxFileUploads} files at a time`
              )
              setChecked(true)
            }
            
          }
          // `files` contains the downloaded data or errors
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      }
    };

    fetchFiles();
  }, [gdFile]);

  useEffect(() => {
    if (authResponse) {
      setGDAuth(authResponse.access_token);
    }
  }, [authResponse]);

  const downloadFile = async (file: any) => {
    try {
      let exportMimeType;
     
      switch (file.mimeType) {
        case 'application/vnd.google-apps.spreadsheet':
          exportMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'application/vnd.google-apps.document':
          exportMimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'application/vnd.google-apps.presentation':
          exportMimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          break;
        case 'application/vnd.google-apps.drawing':
          exportMimeType = 'image/png'; // Or another appropriate format
          break;
        // Add other cases as needed
        default:
          // For other file types, use the default download URL
            const response = await fetch(`https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${gdauth}`,
              }
            });
            if(response.status === 403){
              setgdLoading(false)
              setFailureResMessage("Google Drive feature under verification")
              setChecked(true)
              return;
            }
            const data = await response.blob();
            return data;
      }
      const response = await fetch(`https://www.googleapis.com/drive/v3/files/${file.id}/export?mimeType=${exportMimeType}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${gdauth}`,
        }
      });
      const data = await response.blob();
      return data;
    } catch (error: any) {
      console.log(error)
      setGDBlob([])
      setFailureResMessage("Unable to access file")
      setgdLoading(false)
      if (error?.response) {
        console.error('Error response:', error?.response.data);
        console.error('Error status:', error?.response.status);
        console.error('Error headers:', error?.response.headers);
      } else {
        console.error('Error message:', error?.message);
      }
    }
  };

  // Goodle drive

  const navigate = useNavigate()

  useEffect(() => {
    if (auth?.user?.role == 3) {
      navigate('/error/404')
    } else {
      getFolderTreeForFile(currentParent)
        .then((response) => {
          setFolderTree(response.data.predecessFolders)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    if (isBackFromPages) {
      navigate(`${localStorage.getItem('current-url')}`, { state: localStorage.getItem('current-parent') })
    }
  }, [isBackFromPages])

  useEffect(()=>{
    getMaxFileUploads().then(res =>{
      
      setMaxFileUploads(Number(res.data))
    })
  })

  if (successResMessage) {
    setTimeout(() => {
      setChecked(false)
      setTimeout(() => {
        setSuccessResMessage("");
      }, 200);
    }, 5000);
  }

  if (failureResMessage) {
    setTimeout(() => {
      setChecked(false)
      setTimeout(() => {
        setFailureResMessage("");
      }, 200);
    }, 5000);
  }

  const isValidFileType = (file: Blob) => {
    const validFileType: any = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': true,
      'application/pdf': true,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true,
      'text/plain': true,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': true,
      'application/msword': true,
      'application/vnd.ms-excel': true,
      'image/jpeg': true,
      'image/jpg': true,
      'image/png': true,
      'video/mp4': true,
      'audio/mpeg' : true,
      'video/quicktime': true,
      'image/mov': true
    }
    return validFileType[file.type.toString()] ? true : false
  }

  const getMIMEType = (file: Blob) => {
    return file.type.toString()
  }

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const resetAlerts = () => {
    const success: HTMLElement = document.getElementById("upload-success")!
    const fail: HTMLElement = document.getElementById("upload-fail")!
    if (fail && fail.style) {
      fail.style.display = "none"
    }
    if (success && success.style) {
      success.style.display = "none"
    }
  }

  // triggers when file is dropped
  const handleDrop = async function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    resetAlerts()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files.length <= maxFileUploads) {
        if (isValidFileType(e.dataTransfer.files[0])) {
          setFiles([...files, ...e.dataTransfer.files])
        } else {
          setUploading(false)
          setFailureResMessage("Invalid file format, only supported format are .doc, .docx, .xlsx, .pdf, .pptx, .txt, mp3, mp4, jpg, jpeg, png and mov")
          setChecked(true)
        }

      } else {
        setUploading(false)
        setFailureResMessage(
          `Cannot upload More than ${maxFileUploads} files at a time`
        )
        setChecked(true)
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = async function (e: any) {
    e.preventDefault();
    setUploading("")
    if (e.target.files && e.target.files[0]) {
      if(maxFileUploads>= (e.target.files.length)){
        if (isValidFileType(e.target.files[0])) {
          setFiles([...files, ...Array.from(e.target.files)])
  
        } else {
          setUploading(false)
          setFailureResMessage("Invalid file format, only supported format are .doc, .docx, .xlsx, .pdf, .pptx, .txt, mp3, mp4, jpg, jpeg, png and mov")
          setChecked(true)
        }
      } else {
        setUploading(false)
        setFailureResMessage(
          `Cannot upload More than ${maxFileUploads} files at a time`
        )
        setChecked(true)
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      {!loading &&
        <>
          <div className="d-flex flex-column w-100 px-4">
            <div className="d-flex flex-column w-100">
              <div className='response-box'>
                <div className='directory-path'>
                  <CurrentUploadPath folderTree={folderTree} currentCommunityTitle={currentCommunityTitle} />
                </div>
              </div>
              {uploading ? (
                <>
                  <div className='d-flex justify-content-center' style={{ marginTop: '50px' }}>
                    <div className='w-50px h-50px'>
                      <img className='w-50px h-50px' src={toAbsoluteUrl('/media/utils/upload-loading.gif')} alt="Loading" />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form id="form-file-upload" className="mx-auto mt-5" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange}
                    />
                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                      <div>
                        <span className='fs-2 fw-bold'>
                          <FormattedMessage id='DOCUMENTS.DRAG_AND_DROP.PHRASE1' />
                        </span>
                        <p> (Max {maxFileUploads} )</p>
                        <br />
                        <button className="btn btn-sm btn-flex fw-bold btn-primary mt-5" onClick={onButtonClick}><FormattedMessage id='DOCUMENTS.DRAG_AND_DROP.PHRASE2' /></button>
                        <br />
                        <button 
                          className={`btn btn-sm btn-flex fw-bold mt-5 ${gdLoading ? 'btn-secondary' : 'btn-primary'}`}
                          onClick={handleOpenPicker}
                          // disabled={gdLoading}
                          disabled={!appData.useGDFileUpload || gdLoading}
                        >
                          {
                            gdLoading ?
                            "Please Wait..."
                            :
                            <>
                              Google Drive {" "} <i className='ms-2 fab fa-google-drive' style={{ fontSize:"20px" }}></i>
                            </>
                          }
                        </button>
                        {/* <br />
                        Verification under process */}
                      </div>
                    </label>
                    {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                  </form>
                  {files[0] && (
                    <div className={`card h-50 p-4 my-9`}>
                      <div className=' d-flex justify-content-between'>
                        <h6>Uploading {files.length} files:</h6>
                      </div>

                      {files.map((file: any, index: number) => (
                        <File
                          key={index}
                          index={index}
                          file={file}
                          currentCommunityId={currentCommunityId}
                          currentParent={currentParent}
                          onDelete={() => {
                            let cur = [...files]
                            cur.splice(index, 1)
                            setFiles(cur)
                          }}
                        />
                      ))}
                      
                    </div>
                  )}
                </>
              )}
              <div className="mt-15">

              </div>
              {successResMessage !== undefined && successResMessage !== null && successResMessage !== "" ? (
                <AlertSuccess message={successResMessage} checked={checked} />
              ) : null}

              {failureResMessage !== undefined && failureResMessage !== null && failureResMessage !== "" ? (
                <AlertDanger message={failureResMessage} checked={checked} />
              ) : null}


              <div className='response-box'>
                {/* {uploading &&
                  <div id='upload-info' className={`alert alert-info show`} >
                    <div className='d-flex justify-content-center'>
                      <p className='my-auto'><FormattedMessage id='DOCUMENTS.UPDATING' /></p>
                    </div>
                  </div>
                }
                <div id='upload-success' className={`alert alert-success show`} style={{ display: "none" }} >
                  <div className='d-flex justify-content-center'>
                    <p id='upload-success-text' className='my-auto'></p>
                  </div>
                </div>

                <div id='upload-fail' className={`alert alert-danger show`} style={{ display: "none" }} >
                  <div className='d-flex justify-content-center'>
                    <p id='upload-fail-text' className='my-auto'></p>
                  </div>
                </div> */}

                <div className="mx-auto">
                  <button
                    className={'btn btn-sm btn-flex fw-bold btn-primary'}
                    onClick={() => {
                      navigate("/files")
                      setIsBackFromPages(true)
                    }}
                    disabled={uploading == true ? true : false}
                  >
                    <KTIcon iconName='arrow-circle-left' className='fs-2' />
                    <FormattedMessage id='BUTTON.GO_BACK' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {loading &&
        <div className='d-flex justify-content-center' style={{ marginTop: '50px', marginBottom: '50px' }}>
          <div className='w-50px h-50px'>
            <img className='w-50px h-50px' src={toAbsoluteUrl('/media/utils/upload-loading.gif')} alt="Loading" />
          </div>
        </div>
      }
    </>
  );
};